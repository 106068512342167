.button-next-quiz {
  border: none;
  background-color: rgb(22, 199, 158);
  border-radius: 10px;
  padding: 10px 100px;
  font-weight: 700;
  font-size: 1.2rem;
  color: white;
  margin: 20px 0;
}

.button-next-quiz-verify {
  border: none;
  background-color: #0096ff;
  border-radius: 10px;
  padding: 10px 100px;
  font-weight: 700;
  font-size: 1.2rem;
  color: white;
  margin: 20px 0;
}

.button-next-quiz-desabled {

  opacity: 0.5; 
  pointer-events: none; 
  border: none;
  background-color: gray;
  border-radius: 10px;
  padding: 10px 100px;
  font-weight: 700;
  font-size: 1.2rem;
  color: white;
  margin: 20px 0;
}

.button-next-quiz-correct {
  border: none;
  background-color: rgb(34, 177, 76); /* Verde pentru corect */
  border-radius: 10px;
  color: white;
  font-size: 1.2rem;
  font-weight: 700;
  margin: 20px 0;
  padding: 10px 100px;


}

.button-next-quiz-incorrect {
  border: none;
  background-color: rgb(255, 0, 0); /* Roșu pentru greșit */
  border-radius: 10px;
  font-size: 20px;
  color: white;
  font-size: 1.2rem;
  font-weight: 700;
  margin: 20px 0;
  padding: 10px 100px;

}




.next-message-answer-wrong {
  border: none;
  background-color: #16c79e;
  border-radius: 10px;
  color: white;
  font-size: 20px;
  padding: 10px 60px;
  font-weight: 700;
}


.position-progress-bar {
  background-color: rgb(22, 199, 158);
  width: 100%;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
  0 6px 20px rgba(0, 0, 0, 0.1);
 
}

.position-main-lesson {
  margin: 140px 0;
}

.position-button-lesson {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #edf2f7;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}






.drag-option {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 40px;
  border: 1px solid black;
  border-radius: 20px;
  margin: auto 10px;
}


.horizontal-equation-layout {
display: flex;
align-items: center;
justify-content: center;
gap: 10px;
margin: 30px 0;
flex-wrap: wrap;
}

.equation-box-static {
  border-radius: 10px;
  padding: 10px 10px;
  text-align: center;
  border: 2px solid #c9c9c9;
  color: #2d3846;
  font-size: .9rem;
  font-weight: 500;
}

.equation-box {
min-width: 40px;
min-height: 40px;
padding: 10px 10px;
border: 2px solid #c9c9c9;
border-radius: 8px;
text-align: center;
transition: all 0.3s ease;
display: inline-block;
font-size: .9rem;
font-weight: 500;
white-space: nowrap;
}


.equation-sign {
font-size: 20px;
font-weight: bold;
color: #333;
}

.horizontal-options {
display: flex;
justify-content: center;
flex-wrap: wrap;
gap: 15px;
margin: 20px 0;
}

.equation-option-btn {
  border: 2px solid #f5f5f5;
  border-radius: 5px;
  box-shadow: 0 2px 0 1px #c8d2db;
  color: #2d3846;
  cursor: pointer;
  font-size: .9rem;
  font-weight: 500;
  padding: 10px 20px;
  transition: .3s ease;
}

.placeholder-btn {
  color: transparent;
  background-color: #f0f0f0;
  border: 2px dashed #ccc;
  pointer-events: none;
  opacity: 1;
  transform: translateY(0);
  animation: jumpDown 0.1s ease-out;
}

@keyframes jumpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
}


.equation-option-btn:disabled {
background-color: #ccc;
cursor: not-allowed;
min-height: 30px;
  min-width: 30px;
}

.horizontal-controls {
display: flex;
justify-content: center;
gap: 20px;
margin: 20px 0;
}


.drag-text {
  width: 70%;
  height: 40px;
 text-align: left;
}



.drag-lesson-answer {
 width: 350px;
  display: flex;
  text-align: left;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin: 10px;
  border: none;
  background-color: transparent;
}

.drag-background-options {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 60px auto 0 auto;
  background-color: white;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding: 10px 0;
  width: 100vw;
}

.cursor-pointer {
cursor: pointer;
}


.explanation-lesoon-container-resume {
align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      left: 0;
      margin: 0 auto;
      width: 90%;
}
.drag-controls {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 15px;
}


.control-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #16c79e;
}





.buttons-drag-options {
  margin: 0 auto;
  flex-direction: row;
  text-align: center;
  margin: 10px;
  padding: 5px 30px;
  border: none;
  border-radius: 20px;
  background-color: rgb(22, 199, 158);
  font-weight: bold;
}

.disabled-button {
  opacity: 0.5;
  pointer-events: none;
}

.simple-Button-select,
.simple-Button-select-colored,
.simple-Button-select-correct,
.simple-Button-select-incorrect {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Centrarea textului */
  margin: 17px auto;
  background-color: white;
  height: auto;
  padding: 18px 10px; /* Spațiere interioară uniformă */
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box; /* Asigură că bordura nu modifică dimensiunile */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
              0 6px 20px rgba(0, 0, 0, 0.1); 
  text-align: left; /* Centrarea textului */
  font-weight: 400;
  font-size: 16px;
  font-weight: 500;
  color: #2d3846;
}

.simple-Button-select {
  border: 2px solid whitesmoke;
  margin: 17px 0;
  box-shadow: 0 2px 0 1px #c8d2db;
}


.simple-Button-select-colored {
  background-color: rgba(0, 150, 255, 0.2);
  border: 2px solid rgb(0, 150, 255);
  color: black;
}

.simple-Button-select-correct {
  background-color: rgba(34, 177, 76, 0.2);
  border: 2px solid rgb(34, 177, 76);
  color: black;
}

.simple-Button-select-incorrect {
  background-color: rgba(255, 0, 0, 0.2);
  border: 2px solid rgb(255, 0, 0);
  color: black;
}


.simple-Button-select-colored p {
color: #2d3846;
margin: auto 0;
text-align: left;
word-break: break-word;
font-size: 0.9rem;
line-height: 1.2;
}

.simple-Button-select-correct p {
  color: #2d3846;
  margin: auto 0;
  text-align: left;
  word-break: break-word;
  font-size: 0.9rem;
  line-height: 1.2; 
}

.simple-Button-select-incorrect p {
  color: #2d3846;
  margin: auto 0;
  text-align: left;
  word-break: break-word;
  font-size: 0.9rem;
  line-height: 1.2;
}

.simple-Button-select p {
  color: #2d3846;
  margin: auto 0;
  text-align: left;
  word-break: break-word;
  font-size: 0.9rem;
  line-height: 1.2;
}

.align-input-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 95%;
 
  
  
}

.input-item-answer-type {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin: 0 auto;
  width: 100%;
 
}

.answer-input-item-complete {
  width: 90px;
  margin: 10px 10px;
  border-radius: 10px;
  border: 2px solid gray;
  padding: 5px 15px;
  font-weight: bold;
}

.answer-input-correct {
  width: 90px;
  margin: 10px 10px;
  border-radius: 10px;
  padding: 5px 15px;
  font-weight: bold;
  border: 2px solid green;
  background-color: #d4f5d4; /* Verde deschis */
  color: green;
}

.answer-input-incorrect {
  width: 90px;
  margin: 10px 10px;
  border-radius: 10px;
  padding: 5px 15px;
  font-weight: bold;
  border: 2px solid red;
  background-color: #f8d7da; /* Roșu deschis */
  color: red;
}


.input-title-element-answer {
  width: 100%;
  font-weight: bold;
  margin: auto 0;

}

.description-lesson-text {
  width: 90%;
 
  display: flex;
  flex-direction: column;
}

.image-explanation-lesson {
  width: 80%;
  height: auto;
  margin: 10px 0;
  border-radius: 10px;
}

.tittle-sescrition-lesson {
  margin: 20px 0;
  width: 90%;
  text-align: left;
  font-size: 16px; /* sau 15px */
  line-height: 1.4;     
}

.multiple-answer-lessons {
  width: 50vw;

}

/* Stil implicit pentru răspuns */
.align-multiple-items {
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 30px 0;
  padding: 5px;
  border-radius: 10px;
  border: 2px solid white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
              0 6px 20px rgba(0, 0, 0, 0.1); 
}

/* Contur albastru pentru opțiunile selectate înainte de verificare */
.border-selected {
  background-color: #0096ff33;
  border: 2px solid #0096ff

}

/* Contur verde pentru răspunsurile corecte */
.border-correct {
  background-color: #22b14c33;
  border: 2px solid #22b14c;
}

/* Contur roșu pentru răspunsurile greșite */
.border-incorrect {
  background-color: #f003;
  border: 2px solid red;
}




.explanation-lesoon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}


.explanation-lesoon-container-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: 0 auto;
}

.answer-show-after-button {
  color: black;
  text-align: center;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 25px;
}


.position-main-lesson {
  margin-top: 140px;
  margin-bottom:200px;
}

.simple-select-buttons-description {
  width: 50vw;
}


.text-size-emplanation {
  color: #2d3846;
  font-size: 1rem;
  line-height: 1.2;
  margin: auto 0;
  text-align: left;
  word-break: break-word;
}

.title-simple-select-align {
  width: 90%;
  color: #2d3846;
  font-size: 1rem;
  line-height: 1.2;
  margin: auto 0;
  text-align: left;
  word-break: break-word;
}


/* Stiluri pentru HeartPopup */
.heart-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.heart-popup-box {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  max-width: 350px;
  margin: 0 20px;
}

.heart-popup-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.heart-popup-message {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
}

.heart-popup-button {
  padding: 10px 20px;
  background-color: rgb(22, 199, 158);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}



/* Stiluri pentru EyePopup */
.eye-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.eye-popup-box {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  max-width: 350px;
  margin: 0 20px;
}

.eye-popup-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.eye-popup-message {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
}

.eye-popup-button {
  padding: 10px 20px;
  background-color: rgb(22, 199, 158);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.final-message-lesson-style {
  display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 80px;
      margin: 0 auto;
}


.check-box-multiple-answer {

  width: 20px;
  height: 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
  background-color: white;
  display: flex;
}

/* Stil pentru checkbox-urile corecte */
.checkbox-correct {
  background-color: green;
  border-color: green;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  display: flex;
}

/* Stil pentru checkbox-urile greșite */
.checkbox-incorrect {
  background-color: red;
  border-color: red;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  display: flex;
}

/* Stil pentru label-urile corecte */
.label-correct {
  color: green; 
  margin: 10px 0 0 0;

}

/* Stil pentru label-urile greșite */
.label-incorrect {
  color: red; 
  margin: 10px 0 0 0;
  
}

.align-multiple-items h6 {
  margin: auto 0;
}

@media screen and (max-width: 800px) {

  .explanation-lesoon-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%; 
      left: 0;
      position: fixed;
  }


  .description-lesson-text-explanation {
      width: 90vw;
      display: flex;
      flex-direction: column;
  }

  .explanation-lesoon-container-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      
  }

  .position-main-lesson {
      margin-top: 120px;
      margin-bottom:200px;
  }

  .simple-select-buttons-description {
      width: 90vw;
  }

  .multiple-answer-lessons {
      width: 90vw;
   
    
  }

  .tittle-sescrition-lesson-explanation {
      margin: 20px 0;
      width: 90vw;
      text-align: left;
      font-size: 1.3em;
  }

  .text-size-emplanation {
      width: 90vw;
      margin-left: 0;
      color: #2d3846;
      font-size: 1rem;
      line-height: 1.2;
      text-align: left;
      word-break: break-word;
     
  }

  .title-simple-select-align {
    width: 90%;
    color: #2d3846;
    font-size: 1rem;
    line-height: 1.2;
    margin: auto 0;
    text-align: left;
    word-break: break-word;
  }



  
}

